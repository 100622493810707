



















import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CheckmarkUsp',
  props: {
    label: {
      type: String,
      required: true,
      default: ''
    },
    link: {
      type: String,
      required: true,
      default: '#'
    },
    target: {
      type: String,
      default: '_blank'
    },
    image: {
      type: String,
      default: ''
    },
    isCms: {
      type: Boolean,
      default: false
    }
  }
});
