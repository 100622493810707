







import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { capitalize } from '~/helpers/utils';
import { useVSFContext } from '@vue-storefront/core';

type Type = 'slider' | 'skyscraper' | 'button';
type LocationType = 'Homepage' | 'PLP_List' | 'PLP_Tiles' | 'PDP';

export default defineComponent({
  name: 'AdSlot',
  props: {
    type: {
      type: String as PropType<Type>,
      required: true
    },
    location: {
      type: String as PropType<LocationType>,
      default: false
    }
  },
  setup() {
    const vsfContext = useVSFContext();

    const websiteCountry = vsfContext.$novulo.config.state?.domainConfig?.websiteCountry?.toLowerCase();
    const websiteGroup = vsfContext.$novulo.config.state?.domainConfig?.websiteGroup?.toUpperCase();

    return {
      capitalize,
      websiteCountry,
      websiteGroup
    };
  }
});
