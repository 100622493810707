import { getSSRRequestWebsite } from '~/helpers/utils';

/**
 * Generates structured data for breadcrumbs
 * @param {Array} breadcrumbs - Array of breadcrumb objects with `text` and `link` properties
 * @param {Object} [context] - Nuxt context, required for SSR
 * @returns {Object|null} - The structured data for breadcrumbs or null if breadcrumbs are empty
 */
export default function useBreadcrumbsStructuredData(breadcrumbs, context) {
  if (!breadcrumbs || breadcrumbs.length === 0) {
    return null;
  }

  const domain = getSSRRequestWebsite(context?.req);

  const itemListElement = breadcrumbs
    .filter((breadcrumb) => breadcrumb.text && breadcrumb.link)
    .map((breadcrumb, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: breadcrumb.text,
      ...(breadcrumb.link !== '#'
        ? { item: `${domain}${breadcrumb.link}` }
        : {})
    }));

  if (!itemListElement.length) {
    return null;
  }

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement
  };
}
