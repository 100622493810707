













































import {
  defineComponent,
  PropType,
  computed,
  ref,
  onMounted,
  onUnmounted
} from '@nuxtjs/composition-api';
import useWindowResize from '~/hooks/useResizeWindow';

const flex = {
  start: 'flex-start',
  center: 'center',
  between: 'space-between',
  around: 'space-around',
  end: 'flex-end'
} as const;

type FlexType = keyof typeof flex;

type USPItem = {
  title: string;
  url: string;
  media_path: string;
};

type USPList = USPItem[];

type CMSUSPItem = {
  title: string;
  code: string;
  urls: USPItem[];
};

type CMSUSPData = {
  elements: CMSUSPItem[];
  code: string;
  description: string;
  id: number;
  sequence: number;
  style_class?: string | null;
  style_class_info?: string | null;
};

export default defineComponent({
  name: 'Usps',
  props: {
    uspsList: {
      type: Array as PropType<USPList>,
      required: false
    },
    align: {
      type: String as PropType<FlexType>,
      required: false,
      default: 'center'
    },
    data: {
      type: Object as PropType<CMSUSPData>,
      required: false
    },
    isCms: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const { isDesktop } = useWindowResize();
    const isReady = ref(false);

    const flexStyle = computed(() => flex[props?.align]);
    const itemsList = computed<USPList>(() => {
      if (!props.isCms) return props.uspsList || [];

      return (
        props.data?.elements
          .filter((element) =>
            isDesktop.value
              ? element.code === 'uspbar_item_desktop'
              : element.code === 'uspbar_item_mobile'
          )
          .map((element) => element.urls[0]) || []
      );
    });

    const visibleIndex = ref(0);
    let interval = null;

    const startCarousel = () => {
      interval = setInterval(() => {
        visibleIndex.value = (visibleIndex.value + 1) % itemsList.value.length;
      }, 5000);
    };

    const stopCarousel = () => {
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    };

    onMounted(() => {
      isReady.value = true;

      if (itemsList.value.length > 1) {
        startCarousel();
      }
    });

    onUnmounted(() => {
      stopCarousel();
    });

    return {
      flexStyle,
      itemsList,
      visibleIndex,
      isDesktop,
      isReady
    };
  }
});
